import { range } from "ramda"

import GroupTypes from "lib/leeruniek/social-emotional/group-types"

export const SUPPORT_EMAIL = "support@leeruniek.nl"

export const PUPIL_APPROACHES = {
  ENRICHED: "enriched",
  BASIC: "basic",
  INTENSIVE: "intensive",
  UNDEFINED: "undefined",
}

export const PUPIL_APPROACHES_ORDERED = [
  PUPIL_APPROACHES.ENRICHED,
  PUPIL_APPROACHES.BASIC,
  PUPIL_APPROACHES.INTENSIVE,
]

export const UNDEFINED_PUPIL_APPROACH = PUPIL_APPROACHES.UNDEFINED

export const ALL_OPTION_VALUE = "all"
export const NONE_OPTION_VALUE = "none"

export const SUBJECTS = {
  SP: "SP",
  TBL: "TBL",
  DMT: "DMT",
  RW: "RW",
  WST: "WST",
  RVK: "RvK",
  TVK: "TvK",
}

export const TEST_PROVIDERS = {
  CITO: "CITO",
  DIA: "DIA",
  IEP: "IEP",
}
export const TEST_PROVIDERS_ORDERED = [
  TEST_PROVIDERS.CITO,
  TEST_PROVIDERS.DIA,
  TEST_PROVIDERS.IEP,
]

export const CITO_SCORE_FORMATS = {
  TEST_CODE_EXTRA: "test_part",
  LEVEL_VALUE: "nw", //NIVEAUWAARDEN
  ABILITY_SCORE: "vs", //VAARDIGHEIDSSCORES
  ALPHABETICAL: "alpha",
  ROMAN_NUMERAL: "roman",
  FUNCTIONAL_LEVEL: "functionalLevel", //FUNCTIONERINGSNIVEAUS
  DLE: "dle",
  REFERENCE_LEVEL: "ref",
  PROJECTED_REFERENCE_LEVEL: "pref",
  PERCENTILE_SCORE: "ps",
}

// translates cito_score_formats to their equivalent available
// on the objects coming from API
export const CITO_SCORE_FORMATS_API = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: "levelValue",
  [CITO_SCORE_FORMATS.ABILITY_SCORE]: "abilityScore",
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: "percentileScore",
}

// Different test providers have different score formats sets. Order of
// elements in arrays is important because first element in each set is default score type for certain provider.
// Object below determines available score types for the group and yeargrade levels.
export const TEST_PROVIDER_GROUP_DISPLAYED_SCORE_FORMATS = {
  [TEST_PROVIDERS.CITO]: [
    CITO_SCORE_FORMATS.LEVEL_VALUE,
    CITO_SCORE_FORMATS.ABILITY_SCORE,
    CITO_SCORE_FORMATS.ALPHABETICAL,
    CITO_SCORE_FORMATS.ROMAN_NUMERAL,
    CITO_SCORE_FORMATS.FUNCTIONAL_LEVEL,
    CITO_SCORE_FORMATS.DLE,
    CITO_SCORE_FORMATS.PROJECTED_REFERENCE_LEVEL,
  ],
  [TEST_PROVIDERS.DIA]: [
    CITO_SCORE_FORMATS.LEVEL_VALUE,
    CITO_SCORE_FORMATS.ABILITY_SCORE,
    CITO_SCORE_FORMATS.ALPHABETICAL,
    CITO_SCORE_FORMATS.ROMAN_NUMERAL,
    CITO_SCORE_FORMATS.PROJECTED_REFERENCE_LEVEL,
  ],
  [TEST_PROVIDERS.IEP]: [
    CITO_SCORE_FORMATS.ABILITY_SCORE,
    CITO_SCORE_FORMATS.PERCENTILE_SCORE,
    CITO_SCORE_FORMATS.FUNCTIONAL_LEVEL,
    CITO_SCORE_FORMATS.DLE,
    CITO_SCORE_FORMATS.REFERENCE_LEVEL,
  ],
}

//Object below determines available score types by test provider for the pupil levels.
export const TEST_PROVIDER_DISPLAYED_SCORE_FORMATS = {
  [TEST_PROVIDERS.CITO]: [
    CITO_SCORE_FORMATS.TEST_CODE_EXTRA,
    CITO_SCORE_FORMATS.LEVEL_VALUE,
    CITO_SCORE_FORMATS.ABILITY_SCORE,
    `${CITO_SCORE_FORMATS.ALPHABETICAL}_${CITO_SCORE_FORMATS.ROMAN_NUMERAL}`,
    CITO_SCORE_FORMATS.FUNCTIONAL_LEVEL,
    CITO_SCORE_FORMATS.DLE,
    CITO_SCORE_FORMATS.PROJECTED_REFERENCE_LEVEL,
  ],
  [TEST_PROVIDERS.DIA]: [
    CITO_SCORE_FORMATS.TEST_CODE_EXTRA,
    CITO_SCORE_FORMATS.LEVEL_VALUE,
    CITO_SCORE_FORMATS.ABILITY_SCORE,
    `${CITO_SCORE_FORMATS.ALPHABETICAL}_${CITO_SCORE_FORMATS.ROMAN_NUMERAL}`,
    CITO_SCORE_FORMATS.PROJECTED_REFERENCE_LEVEL,
  ],
  [TEST_PROVIDERS.IEP]: [
    CITO_SCORE_FORMATS.ABILITY_SCORE,
    CITO_SCORE_FORMATS.FUNCTIONAL_LEVEL,
    CITO_SCORE_FORMATS.DLE,
    CITO_SCORE_FORMATS.REFERENCE_LEVEL,
    CITO_SCORE_FORMATS.FUNCTIONAL_LEVEL,
  ],
}

//Some providers have optional score formats that are presented dynamically
// For example, in a table of results, the columns for these scores will be shown only if they are not null for at least one score.
export const OPTIONAL_SCORE_FORMATS_BY_PROVIDER = {
  [TEST_PROVIDERS.IEP]: [CITO_SCORE_FORMATS.FUNCTIONAL_LEVEL],
}

export const SCORE_TYPE_TO_SCORE_FORMAT = {
  level_value: CITO_SCORE_FORMATS.LEVEL_VALUE,
  score: CITO_SCORE_FORMATS.ABILITY_SCORE,
  percentile_score: CITO_SCORE_FORMATS.PERCENTILE_SCORE,
}

export const PUPIL_CHART_SCORE_TYPES = [
  CITO_SCORE_FORMATS.LEVEL_VALUE,
  CITO_SCORE_FORMATS.ABILITY_SCORE,
]

export const SCORE_TYPE_DECIMAL_PLACES = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: 1,
  [CITO_SCORE_FORMATS.ABILITY_SCORE]: 0,
}

export const SCORE_FORMATS_WITH_ENABLED_BUCKETING = [
  CITO_SCORE_FORMATS.ROMAN_NUMERAL,
  CITO_SCORE_FORMATS.ALPHABETICAL,
  CITO_SCORE_FORMATS.LEVEL_VALUE,
  CITO_SCORE_FORMATS.PROJECTED_REFERENCE_LEVEL,
  CITO_SCORE_FORMATS.REFERENCE_LEVEL,
]

export const METHOD_SCORE_FORMATS = {
  LABEL: "label",
  PERCENTAGE: "percentage",
}

export const MONTHS = {
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11,
}

export const TEST_TYPES = {
  NATIONAL: "national",
  METHOD: "method",
}

export const METHOD_SCORE_SECTIONS = {
  GOOD: "good",
  WARNING: "warning",
}

export const METHOD_SCORES_SHORT = {
  GOOD: "G", //GOED
  SUFFICIENT: "V", //VOLDOENDE
  MODERATE: "M", //MATIG
  INSUFFICIENT: "O", //ONVOLDOENDE
}

export const METHOD_SCORES_SHORT_BY_SECTION = {
  [METHOD_SCORE_SECTIONS.GOOD]: [
    METHOD_SCORES_SHORT.GOOD,
    METHOD_SCORES_SHORT.SUFFICIENT,
  ],
  [METHOD_SCORE_SECTIONS.WARNING]: [
    METHOD_SCORES_SHORT.MODERATE,
    METHOD_SCORES_SHORT.INSUFFICIENT,
  ],
}

// yearclassGrade from which pupils scores are valuable for school level analysis
export const SCORE_VALUABLE_GRADE = 3
export const FINAL_GRADE = 8
export const GRADES_WITH_SCORES = range(SCORE_VALUABLE_GRADE, FINAL_GRADE + 1)
export const ALL_GRADES = range(1, FINAL_GRADE + 1)
export const ALL_GRADES_GROUP_PLAN = range(0, FINAL_GRADE + 1)

export const DEFAULT_GRAPH_PAGE_SIZE = 16

export const YEAR_TIMES = {
  COVID: "X",
  BEGINNING: "B",
  MIDDLE: "M",
  END: "E",
}

export const YEAR_TIMES_ORDERED = [
  YEAR_TIMES.COVID,
  YEAR_TIMES.BEGINNING,
  YEAR_TIMES.MIDDLE,
  YEAR_TIMES.END,
]

export const STILL_SCORE_DIFF_LIMITS = {
  MIN: -0.2,
  MAX: 0.2,
}

export const PERCENTILE_SCORE_DIFF_LIMITS = {
  MIN: -6,
  MAX: 6,
}

export const MAX_METHOD_BLOCKS = 12

// How long after the most recent Cito test to show the message which links to help with Cito
// analysis. The warning message is displayed in the worksheet instruction groups and Cito
// results sections.
export const CITO_ANALYSIS_WARNING_DELAY = [1, "month"]

export const AVI_SCORE = "AVI"
//This map determines relations between preschool and school subjects
export const PRESCHOOL_SUBJECT_MAP = {
  [SUBJECTS.RVK]: [SUBJECTS.RW],
  [SUBJECTS.TVK]: [SUBJECTS.SP, SUBJECTS.WST],
}

export const NOTE_CHAR_LIMIT = 5000
export const NOTE_INPUT_ROWS = 5
export const NOTE_ARCHIVE_DELAY = 1000

export const SIZES = {
  SMALL: "small",
}

export const SOCIAL_EMOTIONAL_SHIFT_VALUES_MAP = {
  [-1]: GroupTypes.INFREQUENT,
  [0]: GroupTypes.EXPECTED,
  [1]: GroupTypes.FREQUENT,
}

export const YEAR_POSITIONS = {
  CURRENT: "current",
  NEXT: "next",
  PREV: "previous",
  OTHER: "other",
}
export const YEAR_POSITION_COLORS = {
  [YEAR_POSITIONS.CURRENT]: "#3DCCB4", //green
  [YEAR_POSITIONS.NEXT]: "#F49606", //orange
  [YEAR_POSITIONS.PREV]: "#631F6D", //violet
  [YEAR_POSITIONS.OTHER]: "#888A8C", //Medium grey
}

export const SCROLL_MARGIN = 200

export const SUBJECT_DISPLAY_TYPES = {
  TEST: "test",
  SUBJECT: "subject",
}
export const HEADER_HEIGHT = 90
export const PLAN_TEMPLATE_TYPES = {
  TEMPLATE: "template",
  PLAN: "plan",
}

export const DEFAULT_SCORE_PUPIL_CHART_LIMITS = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: { low: 0, high: 5 },
  [CITO_SCORE_FORMATS.ABILITY_SCORE]: { low: 0, high: 200 },
}
//default limits for Pupil VS scores chart
export const ABILITY_SCORE_PUPIL_CHART_LIMITS = {
  [TEST_PROVIDERS.CITO]: {
    [SUBJECTS.DMT]: {
      LOVS: { low: 0, high: 150 },
      2018: { low: 0, high: 160 },
    },
    [SUBJECTS.RW]: {
      LOVS: { low: 0, high: 180 },
      "LOVS DIGI": { low: 20, high: 180 },
      "3.0": { low: 0, high: 320 },
      "3.0 DIGI": { low: 0, high: 340 },
      "S(B)O LOVS": { low: 0, high: 180 },
      "S(B)O LOVS DIGI": { low: 0, high: 340 },
    },
    [SUBJECTS.SP]: {
      LOVS: { low: 65, high: 200 },
      "LOVS DIGI": { low: 75, high: 200 },
      "3.0": { low: 0, high: 450 },
      "3.0 DIGI": { low: 0, high: 400 },
    },
    [SUBJECTS.WST]: {
      LOVS: { low: -100, high: 180 },
      "LOVS DIGI": { low: -10, high: 180 },
    },
    [SUBJECTS.TBL]: {
      LOVS: { low: -100, high: 150 },
      "LOVS DIGI": { low: -100, high: 150 },
      "S(B)O LOVS": { low: -100, high: 120 },
      "S(B)O LOVS DIGI": { low: -100, high: 150 },
      "3.0": { low: 0, high: 280 },
    },
  },
  [TEST_PROVIDERS.DIA]: { low: 0, high: 1200 },
  [TEST_PROVIDERS.IEP]: { low: 0, high: 120 },
}

//default limits for Pupil NW scores chart
export const LEVEL_VALUE_PUPIL_CHART_LIMITS = {
  [TEST_PROVIDERS.CITO]: {
    low: 0,
    high: 5,
  },
  [TEST_PROVIDERS.DIA]: {
    low: 0,
    high: 5,
  },
}

export const ROMAN_SCORE_CATEGORIES = ["I", "II", "III", "IV", "V"]
export const ROMAN_SCORE_MIN = ROMAN_SCORE_CATEGORIES[0]
export const ROMAN_SCORE_MAX =
  ROMAN_SCORE_CATEGORIES[ROMAN_SCORE_CATEGORIES.length - 1]

export const PUPIL_CHART_TIME_INTERVAL_SHORT = 3
export const PUPIL_CHART_TIME_INTERVAL_LONG = 6

export const DEFAULT_LOW_THRESHOLD = 2
export const DEFAULT_HIGH_THRESHOLD = 4
export const LATEST_RESULTS_MAX_AGE_MONTHS = 24

export const IG_REASSIGMENT_ALERT_START_MONTH = MONTHS.JUNE
export const IG_REASSIGMENT_ALERT_END_MONTH = MONTHS.AUGUST

// Translation for the short version:
// short version of "niveauwaarde" is "nw"
export const SHORT_I18N = "_short"

// Translation for the singular version:
// short version of "scores" is "score"
export const SINGULAR_I18N = "_singular"

export const NATIONAL_TEST_RESULT_CHART_LIMITS = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: {
    min: 0,
    max: 5,
  },
  [CITO_SCORE_FORMATS.ABILITY_SCORE]: {
    min: 0,
    max: 120,
  },
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: {
    min: 0,
    max: 100,
  },
}

export const NATIONAL_TEST_RESULT_DEFAULT_AMBITIONS = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: {
    min: 2,
    max: 4,
  },
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: {
    min: 25,
    max: 75,
  },
}

export const NATIONAL_TEST_RESULT_AMBITION_LIMITS = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: {
    min: 0,
    max: 5,
  },
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: {
    min: 0,
    max: 100,
  },
}

export const NATIONAL_TEST_RESULT_AMBITION_STEP = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: 0.1,
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: 1,
}

export const NATIONAL_TEST_RESULT_GROUP_CHART_TICK_INTERVAL = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: 1,
  [CITO_SCORE_FORMATS.ABILITY_SCORE]: 20,
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: 20,
}

// the way an empty score gets rendered in the UI
export const SCORE_EMPTY_VALUE = "-"

export const METHOD_RESULT_CHART_TICKS = [
  METHOD_SCORES_SHORT.INSUFFICIENT,
  METHOD_SCORES_SHORT.MODERATE,
  METHOD_SCORES_SHORT.SUFFICIENT,
  METHOD_SCORES_SHORT.GOOD,
]
export const TEST_PROVIDER_DEFAULT_SCORE_FORMATS = {
  [TEST_PROVIDERS.CITO]: CITO_SCORE_FORMATS.LEVEL_VALUE,
  [TEST_PROVIDERS.DIA]: CITO_SCORE_FORMATS.LEVEL_VALUE,
  [TEST_PROVIDERS.IEP]: CITO_SCORE_FORMATS.ABILITY_SCORE,
}
export const TEST_PROVIDER_SUBJECTS_WITH_REFERENCE_LEVEL = {
  [TEST_PROVIDERS.CITO]: [SUBJECTS.RW, SUBJECTS.TBL],
  [TEST_PROVIDERS.DIA]: [SUBJECTS.RW, SUBJECTS.TBL, SUBJECTS.SP],
  [TEST_PROVIDERS.IEP]: [SUBJECTS.RW, SUBJECTS.TBL, SUBJECTS.SP],
}

// Max value of national average for national test chart
export const NATIONAL_AVERAGE_MAX = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: 4,
  [CITO_SCORE_FORMATS.ABILITY_SCORE]: 80,
  [CITO_SCORE_FORMATS.REFERENCE_LEVEL]: {
    [SUBJECTS.RW]: "1S",
    [SUBJECTS.SP]: "2F",
    [SUBJECTS.TBL]: "2F",
  },
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: 75,
}

// Max value of national average for national test chart
export const NATIONAL_AVERAGE_MIN = {
  [CITO_SCORE_FORMATS.LEVEL_VALUE]: 2,
  [CITO_SCORE_FORMATS.ABILITY_SCORE]: 60,
  [CITO_SCORE_FORMATS.REFERENCE_LEVEL]: "1F",
  [CITO_SCORE_FORMATS.PERCENTILE_SCORE]: 25,
}

export const LAYERS = {
  GROUP: "group",
  PUPIL: "pupil",
}

export const DIRECTIONS = {
  ASC: "asc",
  DESC: "desc",
}

export const TEST_NOTES_WITHOUT_COLLAPSE_MAX = 1
export const OUTFLOW_REFERENCE_LEVEL_SUBJECTS_ORDER = [
  SUBJECTS.RW,
  SUBJECTS.TBL,
  SUBJECTS.WST,
]
export const SCHOOL_OUTFLOW_ALL_SUBJECTS_VALUE = null
export const TIMELINE_NOTES_AMOUNT_SESSION_STORAGE_KEY = "timeline_notes_amount"
export const YEARCLASS_ID_SESSION_STORAGE_KEY = "yearclassId"
export const OUTFLOW_REFERENCE_LEVELS_ORDER = ["1F", "1S/2F"]

export const LOGIN_REDIRECT_STORAGE_KEY = "loginRedirect"

export const NO_OUTFLOW_REFERENCE_LEVELS_YEAR = 2019

export const SUBJECT_TO_REFERENCE_LEVEL_GRAPH_TITLE = {
  // We use Leeruniek subjects as proxies for subjects used by DUO which are a bit more extensive
  // (for more details see comments in webapi)
  // So we must map the subject to the text that will be shown in the graph.
  // The values of this object are keys in the translation languagePack
  [SUBJECTS.RW]: "math_DUO",
  [SUBJECTS.TBL]: "reading_DUO",
  [SUBJECTS.WST]: "language_DUO",
}

export const API_ERRORS = {
  // Error code that indicates that user is not connected to any paying/trial school
  // In that case, we show a different login error message
  NO_CONNECTED_PAYING_SCHOOL_ERROR: "NO_CONNECTED_PAYING_SCHOOL_ERROR",
  TOTP_CHALLENGE_REQUIRED: "TOTP challenge is required",
  TOTP_CHALLENGE_INVALID: "TOTP challenge is invalid",
}

export const ONE_MONTH_MILLISECONDS = 2629800000

export const PUPIL_TEST_CHART_TICK_INTERVAL = {
  // We need to set tickInterval to Highcharts chart in order to have consistent xAxis. Otherwise it will be generated dynamically, based on data.
  [TEST_TYPES.NATIONAL]: 4 * ONE_MONTH_MILLISECONDS, //4 months
  [TEST_TYPES.METHOD]: ONE_MONTH_MILLISECONDS, //1 month
}

// Padding/jitter used to separate points in the graph such that both datapoints are visible in case they have the same x value.
export const PUPIL_CHART_FAKE_INTERVAL = 5

export const MONTHS_DUTCH_SHORT = [
  "jan",
  "feb",
  "mrt",
  "apr",
  "mei",
  "jun",
  "jul",
  "aug",
  "sep",
  "okt",
  "nov",
  "dec",
]

// Dutch last name prefixes (NL: tussenvoegsel), ordered from longest to shortest
export const DUTCH_LAST_NAME_PREFIXES_SORTED = [
  "de",
  "der",
  "den",
  "van",
  "van de",
  "van der",
  "van den",
  "te",
  "ter",
  "ten",
].sort((a, b) => b.length - a.length)

export const TUTORIAL_LINKS = [
  {
    urlKey: "doorbell_half_yearly_analysis_link_href",
    titleKey: "doorbell_half_yearly_analysis_link_text",
  },
  {
    urlKey: "doorbell_users_tutorial_href",
    titleKey: "doorbell_users_tutorial_text",
  },
  {
    urlKey: "doorbell_pupil_notes_tutorial_href",
    titleKey: "doorbell_pupil_notes_tutorial_text",
  },
  {
    urlKey: "doorbell_methods_tutorial_link_href",
    titleKey: "doorbell_methods_tutorial_link_text",
  },
  {
    urlKey: "doorbell_education_plan_tutorial_href",
    titleKey: "doorbell_education_plan_tutorial_text",
  },
  {
    urlKey: "doorbell_handover_link_href",
    titleKey: "handover",
  },
  {
    urlKey: "doorbell_group_discussion_link_href",
    titleKey: "group_discussion",
  },
  {
    urlKey: "doorbell_multifactor_auth_app_link_href",
    titleKey: "two_factor_authentication",
  },
]

export const DOWN_ARROW = { keyCode: 40 }
export const ENTER = { keyCode: 13 }

export const SUPPORT_TICKET_OPTIONS = [
  {
    label: "tests",
    value: "tests",
  },
  {
    label: "users",
    value: "users",
  },
  {
    label: "key_definitions",
    value: "key_definitions",
  },
  {
    label: "pupil",
    value: "pupil",
  },
  {
    label: "other",
    value: "other",
  },
]

export const USER_SETTINGS_OPTIONS = {
  SCHOOL_GRAPH_LEVEL_ONE_TOGGLE: "displayHiddenScoresOnSchoolGraphLevelOne",
  SCHOOL_GRAPH_LEVEL_TWO_TOGGLE: "displayHiddenScoresOnSchoolGraphLevelTwo",
  SCHOOL_GRAPH_LEVEL_THREE_TOGGLE: "displayHiddenScoresOnSchoolGraphLevelThree",
  YEARGRADE_GRAPH_TOGGLE: "displayHiddenScoresOnYeargradeGraph",
  YEARCLASS_NATIONAL_GRAPH_TOGGLE:
    "displayHiddenScoresOnYearclassNationalGraph",
  YEARCLASS_METHOD_GRAPH_TOGGLE: "displayHiddenScoresOnYearclassMethodGraph",
  YEARCLASS_TEST_MOMENT_NOTES_TOGGLE:
    "displayAllRelatedYearclassTestMomentNotes",
  YEARCLASS_TEST_MOMENT_FORWARD_LOOKING_NOTES_TOGGLE:
    "displayAllRelatedYearclassTestMomentForwardLookingNotes",
  YEARCLASS_METHOD_TEST_NOTES_TOGGLE: "displayAllRelatedMethodTestNotes",
}

export const SCHOOL_CHART_SETTINGS = [
  USER_SETTINGS_OPTIONS.SCHOOL_GRAPH_LEVEL_ONE_TOGGLE,
  USER_SETTINGS_OPTIONS.SCHOOL_GRAPH_LEVEL_TWO_TOGGLE,
  USER_SETTINGS_OPTIONS.SCHOOL_GRAPH_LEVEL_THREE_TOGGLE,
]

export const MAX_YEARS_TO_DISPLAY = 6

export const HIGHCHARTS_COMMON_TEXT_STYLES = {
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontStyle: "normal",
  lineHeight: "19px",
}

export const HIGHCHARTS_PLOT_BAND_LABEL_CLASS = "highcharts-plot-band-label"
export const HIGHCHARTS_X_AXIS_LABELS_CONTAINER_CLASS =
  "highcharts-xaxis-labels"

export const TEXT_FORMATS = {
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",
  LINK: "link",
  BULLET_LIST: "bulletList",
  ORDERED_LIST: "orderedList",
  TASK_LIST: "taskList",
}

export const EDITOR_ACTIONS = {
  HORIZONTAL_RULE: "horizontalRule",
  CLEAR_FORMAT: "clearFormat",
  UNDO: "undo",
  REDO: "redo",
}

export const NOTE_TYPES = {
  YEARCLASS_TEST_MOMENT: "YEARCLASS_TEST_MOMENT",
  YEARCLASS_METHOD_TEST: "YEARCLASS_METHOD_TEST",
  PUPIL_TEST_SCORE: "PUPIL_TEST_SCORE",
  METHOD_TEST_RESULT: "METHOD_TEST_RESULT",
  PUPIL: "PUPIL",
  SCHOOL: "SCHOOL",
}

export const SCHOOL_PAGE_CHART_BREAKPOINT = 1220

export const SNACKBAR_DEFAULT_TIMEOUT_MILLISECONDS = 3000
